import React, { lazy, useEffect, useState } from "react";
import AppRoute from "./route";
import Layout from "../container/layout";
import { connect } from "react-redux";

const QuestionPage = lazy(() => import("../pages/questionPage"))
const InstructionPage = lazy(() => import("../pages/instructionPage"))
const DashboardPage = lazy(() => import("../pages/dashboardPage"))
const FinalPage = lazy(() => import("../pages/finalPage"))
const LoginPage = lazy(() => import("../pages/loginPage"))
const FeedBackPage = lazy(() => import("../pages/feedbackPage"))
const ResultPage = lazy(() => import("../pages/resultPage"))
const ResultTablePage = lazy(() => import("../pages/resultTablePage"))


const openRoutes = [
    { path: "/", exact: true, component: LoginPage }
];

const userRoutes = [
    { path: "/question/:id", exact: true, component: QuestionPage },
    { path: "/instruction", exact: true, component: InstructionPage },
    { path: "/dashboard", exact: true, component: DashboardPage },
    { path: "/final_page", exact: true, component: FinalPage },
    { path: "/feedback", exact: true, component: FeedBackPage },
    { path: "/", exact: true, component: LoginPage },
    // { path: "/result", exact: true, component: ResultPage },
    { path: "/result-details", exact: true, component: ResultTablePage }
];


const PagesRoute = (props) => {
    const [allRoues, setAllRoues] = useState([])

    useEffect(() => {
        if (props.auth.auth?.token) {
            setAllRoues([...openRoutes, ...userRoutes])
        }
        else {
            setAllRoues([...openRoutes])
        }

    }, [props.auth.auth])
    // console.log(props.auth);
    return (
        <Layout>
            {allRoues.map((route, idx) => (
                <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
            ))}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PagesRoute);