import Notiflix from 'notiflix';

export const loaderStart = () => {
    Notiflix.Notify.init({ width: '300px', fontSize: '14px', timeout: 5000, messageMaxLength: 200, });
    Notiflix.Loading.standard('Loading...', { svgColor: "#f7941e" });
}

export const loaderEnd = () => {
    Notiflix.Loading.remove()
}

//RealTime Notifications
export const notification = (status, msg = "Success") => {
    if (status) {
        Notiflix.Loading.remove()
        Notiflix.Notify.success(msg)
    }
    else {
        Notiflix.Loading.remove()
        Notiflix.Notify.failure(msg)
    }

}
