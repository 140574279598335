import * as actionTypes from "./actionTypes";

const initialState = {
    auth: null,
    allExam:{}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_AUTH_DATA:
            return {
                ...state,
                auth: action.payload,
            };
            case actionTypes.UPDATE_EXAM:
            return {
                ...state,
                allExam: action.payload,
            };
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
