import React from "react";
import classes from "./styles.module.scss";

const Footer = () => {
    return (
        <div className={classes.footer_container}>
            <div class={classes.footer_content}>
                <p>
                    <a href="#">
                        Copyright © Codebucket Solutions {new Date().getFullYear()}
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Footer;