import React from "react";
import classes from "./styles.module.scss";
import Q_img from "../../../assets/images/Blue Square.jpg";

const RadioSelect = (props) => {
    return (
        <div className={classes.radio_select}>
            <input
                id={props.id}
                type="radio"
                for={props.id}
                {...props}
                name={"radio"} />
            <label className={classes.label_text} for={props.for}>{props.option}</label>
            {/* <div className={classes.option_image}>
                <img src={props.option_image}/>
            </div> */}
        </div>
    )
}

export default RadioSelect;