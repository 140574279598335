import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";

import classes from "./styles.module.scss";
import RightStatus from "./rightStatus";
import LeftQuestion from "./leftQuestion";
import PrimaryButton from "../../components/buttons";
import Loader from "../../components/smallLoader";
import InstructionModal from "./instructionModal";
import FinalSubmitModal from "./finalSubmitModal";
import { onGetQuestions, updateQuestions, onSaveQuestion, onSubmit } from '../../store/questions/actions';

const QuestionContainer = (props) => {

    const { ...params } = useParams()
    const navigate = useNavigate();
    const [showInstruction, setShowInstruction] = useState(false);
    const [showFinalSubmitModal, setShowFinalSubmitModal] = useState(false);

    useEffect(() => {
        if (props.auth.allExam?.userCurrentSlotData?.length) {
            props.onGetQuestions(props.auth.allExam?.userCurrentSlotData[0].examId)
        }
    }, [])

    const getCurrentQuestion = () => {
        if (props.exam.questions?.length) {
            return props.exam.questions[parseInt(params.id) - 1]
        }
        else
            return null
    }

    const updateSaved = (e, number) => {
        let _questions = [...props.exam.questions];
        // console.log(_questions);
        _questions[parseInt(params.id) - 1].currResponse = number
        props.updateQuestions(_questions)
    }


    const onClear = () => {
        let _questions = [...props.exam.questions];
        // console.log(_questions);
        _questions[parseInt(params.id) - 1].currResponse = 0
        props.updateQuestions(_questions)
        saveQuestions("clear", _questions)
    }


    const saveQuestions = (action, questions) => {


        let _questions = questions ? [...questions] : [...props.exam.questions];
        let question = { ..._questions[parseInt(params.id) - 1] }

        let answer = question.currResponse >= 0 ? question.currResponse : question.userResponse;
        let hasSkipped = question.hasSkipped;
        let hasMarkedForReview = question.hasMarkedForReview;
        let next = 0;
        if (action == "clear") {
            answer = 0
            hasMarkedForReview = 0
            hasSkipped = 1;
        }
        else if (action == "skipped") {
            hasSkipped = 1;
        }
        else if (action == "review") {
            hasMarkedForReview = 1;
            next = params.id
        }
        else if (action == "next") {
            next = params.id
            hasMarkedForReview = 0
            hasSkipped = 1;
        }
        else if (action == "save") {
            hasMarkedForReview = 0
            hasSkipped = 1;
        }

        if (!answer) {
            hasSkipped = 1;
        }

        let data = {
            "answers": [
                {
                    "exam_id": props.auth.allExam?.userCurrentSlotData[0].examId,
                    "quest_id": question.mcqId,
                    "answer": answer ? answer : 0,
                    "has_skipped": hasSkipped,
                    "has_marked_for_review": hasMarkedForReview
                }
            ]
        }
        console.log(data, next);

        props.onSaveQuestion(data, parseInt(params.id) - 1, next, navigate)
    }

    // console.log(props.exam.questions);


    return (
        <div className={classes.question_container}>
            <div className={classes.question_content}>

                <div className={classes.top_question_status_content}>
                    {
                        getCurrentQuestion() &&
                        <>
                            <div className={classes.left_question_section}>
                                <LeftQuestion
                                    question={getCurrentQuestion()}
                                    number={params.id}
                                    handleChange={updateSaved}
                                />
                            </div>
                            <div className={classes.right_status_section}>
                                <RightStatus
                                    length={props.exam.questions.length}
                                    number={params.id}
                                    questions={props.exam.questions}
                                />
                            </div>
                        </>
                    }

                </div>

                <div className={classes.bottom_action_buttons}>

                    <div className={classes.bottom_action_buttons_container}>
                        <div className={classes.left_action_buttons}>
                            {
                                props.exam.loader && <Loader />
                            }
                            <PrimaryButton
                                onClick={() => saveQuestions("save")}
                                color="white"
                                buttonText="Save"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#ffb300"
                                fontSize="15px"
                            />
                            <PrimaryButton
                                onClick={() => saveQuestions("next")}
                                color="white"
                                buttonText="Save & Next"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#009bfc"
                                fontSize="15px"
                            />
                            <PrimaryButton
                                onClick={() => saveQuestions("review")}
                                color="white"
                                buttonText="Save & Mark as Review"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#0085ad"
                                fontSize="15px"
                            />
                            <PrimaryButton
                                onClick={() => onClear()}
                                color="white"
                                buttonText="Clear"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#db0028"
                                fontSize="15px"
                            />
                        </div>

                        <div className={classes.right_action_buttons}>
                            <PrimaryButton
                                // onClick={() => props.onSubmit(navigate)}
                                color="white"
                                buttonText="Finish Exam"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#db0028"
                                fontSize="15px"
                                onClick={() => setShowFinalSubmitModal(true)}
                            />
                            <PrimaryButton
                                color="white"
                                buttonText="Instruction"
                                margin="0px 0px 0px 10px"
                                backgroundColor="#66bd84"
                                fontSize="15px"
                                onClick={() => setShowInstruction(true)}
                            />
                        </div>
                    </div>
                </div>
                <InstructionModal open={showInstruction} close={() => setShowInstruction(false)} />
                {
                    showFinalSubmitModal && <FinalSubmitModal
                        open={showFinalSubmitModal}
                        close={() => setShowFinalSubmitModal(false)}
                        finalSubmit={() => props.onSubmit(navigate)}
                        questions={props.exam.questions}
                    />
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
        exam: state.Exam
    }
}

const mapDispatchToProps = { onGetQuestions, updateQuestions, onSaveQuestion, onSubmit }
export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
