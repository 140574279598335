import React, { useState, useEffect, useRef } from 'react';

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch('https://www.google.com/', {
                mode: 'no-cors',
            })
                .then(() => !isOnline && setIsOnline(true))
                .catch(() => isOnline && setIsOnline(false));
        }, 5000);

        return () => clearInterval(interval);
    }, [isOnline]);

    return { isOnline };
};
