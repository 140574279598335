import logo from './logo.svg';
import { connect } from 'react-redux';

import './App.css';
import Routes from "./routes";
import { getDataFromLocal } from "./store/auth/actions"
import { useEffect } from 'react';

function App(props) {
    useEffect(() => {
        props.getDataFromLocal()
    }, [])

    return (
        <div className="App">
            <Routes />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = { getDataFromLocal }
export default connect(mapStateToProps, mapDispatchToProps)(App);

