import React from "react";
import parse from 'html-react-parser'

import classes from "./styles.module.scss";
import Q_img from "../../../assets/images/Blue Square.jpg";
import RadioSelect from "../../../components/options/radioSelect";

const LeftQuestion = (props) => {
    // console.log(props);

    const checkResponse = (pos) => {
        if (props.question?.currResponse) {
            return props.question?.currResponse == pos ? true : false
        }
        else if (props.question?.userResponse) {
            return props.question?.userResponse == pos ? true : false
        }
        return false;
    }

    return (
        <div className={classes.left_question}>

            <div className={classes.question_header}>
                <p className={classes.question_no}>Question <span>{String(props.number).padStart(3, '0')}</span></p>
                <p className={classes.question_type}>SINGLE CHOICE QUESTIONS</p>
            </div>

            <div className={classes.question_option_section}>
                <div className={classes.question_section}>
                    <p className={classes.question}>
                        <span>Q) &nbsp; </span>
                        {parse(props.question?.question)}
                    </p>
                    {/* <div className={classes.question_img}>
                        <img src={Q_img} />
                    </div> */}
                </div>

                <div className={classes.options_section}>
                    <p className={classes.option_head}>Options</p>
                    <div className={classes.options}>
                        <RadioSelect
                            option={parse(props.question?.option1)}
                            id="User interface"
                            for="User interface"
                            checked={checkResponse(1)}
                            option_image={Q_img}
                            onChange={(e) => props.handleChange(e, 1)}
                        />
                        <RadioSelect
                            option={parse(props.question?.option2)}
                            id="Language translator"
                            for="Language translator"
                            checked={checkResponse(2)}
                            option_image={Q_img}
                            onChange={(e) => props.handleChange(e, 2)}

                        />
                        {
                            props.question?.option3 && <RadioSelect
                                option={parse(props.question?.option3)}
                                id="Platform"
                                for="Platform"
                                option_image={Q_img}
                                checked={checkResponse(3)}
                                onChange={(e) => props.handleChange(e, 3)}
                            />
                        }
                        {
                            props.question?.option4 && <RadioSelect
                                option={parse(props.question?.option4)}
                                id="Screen saver"
                                for="Screen saver"
                                option_image={Q_img}
                                checked={checkResponse(4)}
                                onChange={(e) => props.handleChange(e, 4)}
                            />
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftQuestion;