
import { apiFunction } from "../../apiCall/function";
import * as API from "../../apiCall/urls/exam";

export const addLanguage = (data) => {
    return apiFunction(API.ADD_LANGUAGE, "post", data, true, null);
};

export const getQuestions = (id) => {
    return apiFunction(API.GET_QUESTION + id, "get", null, true, null);
};


export const saveQuestions = (data) => {
    return apiFunction(API.SAVE_QUESTION, "put", data, true, null);
};

export const submitExam = (data) => {
    return apiFunction(API.SUBMIT_EXAM, "post", data, true, null);
};

export const getResult = (id) => {
    return apiFunction(API.GET_RESULT + id, "get", null, true, null);
};

export const updateTime = (data) => {
    return apiFunction(API.UPDATE_TIME, "PUT", data, true, null);
};

export const updateFeedback = (data) => {
    return apiFunction(API.UPDATE_FEEDBACK, "post", data, true, null);
};