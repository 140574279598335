import React, { useState } from "react";
import classes from "./styles.module.scss";
import PrimaryButton from "../../components/buttons";
import { useNavigate } from "react-router-dom";

const InstructionContainer = ({ display, ...props }) => {

    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);

    const canBeSubmitted = () => {
        return checked ? setIsDisabled(true) : setIsDisabled(false);
    };

    const onCheckboxClick = () => {
        setChecked(!checked);
        return canBeSubmitted();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        navigate("/final_page")
    }

    return (
        <div className={classes.instruction_container}>
            <div className={classes.instruction_content} style={props.style}>
                <h2>INSTRUCTIONS / निर्देश</h2>

                <ol>
                    <li>
                        <p>Please read the instructions carefully.</p>
                        <span>कृपया निर्देशों को ध्यान से पढ़ें।</span>
                    </li>

                    <li>
                        <p><span>Question Dash Board (QDB):-</span> You must continuously keep looking on the QDB appearing on the right hand side of your screen. On this QDB, you will find all the 100 questions which will keep changing colors as you proceed with your exam. You can go to any question, either backward or forward, by clicking on that question on the pop up window. </p>
                        <span>आपको अपनी स्क्रीन के दायीं ओर दिखाई देने वाली QDB को लगातार देखना जारी रखना चाहिए। इस QDB पर आपको सभी 100 प्रश्न मिलेंगे जिन्हें हल करते हुए आगे बढ़ने के क्रम में ये रंग बदलते रहेंगे। QDB पर उस प्रश्न पर क्लिक करके आप आगे या पीछे किसी भी प्रश्न पर जा सकते हैं।</span>

                        <ul>
                            <li>
                                <p>(a) Question appearing with no <span>color</span> indicates that you have not yet attempted that question.</p>
                                <span>(ए) कोई रंग नहीं दिखने वाला प्रश्न इंगित करता है कि आपने अभी तक उस प्रश्न का प्रयास नहीं किया है।</span>
                            </li>
                            <li>
                                <p>(b)Question appearing with <span>green color</span> indicates that you have attempted that question.</p>
                                <span>(बी) हरे रंग में दिखने वाला प्रश्न इंगित करता है कि आपने उस प्रश्न का प्रयास किया है।</span>
                            </li>
                            <li>
                                <p>(c) Question appearing with <span>purple color</span> indicates that you have, although answered that question, but you would like to revisit the question at a later stage and may review the answer, if time permits.</p>
                                <span>(सी) बैंगनी रंग में दिखाई देने वाला प्रश्न इंगित करता है कि आप ने हालांकि उस प्रश्न का उत्तर दिया है लेकिन बाद में उस प्रश्न पर आप फिर से विचार करना चाहते हैं और समय मिलने पर उत्तर की समीक्षा कर सकते है।</span>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>How to mark your answers:-</p>
                        <span>उत्तर देने की प्रक्रिया।</span>

                        <ul>
                            <li>
                                <p>(a) Select the correct option and click "Save & Next". This means that you have answered that question and you are moving to the next question.</p>
                                <span>(ए) सही विकल्प का चयन करें और "Save & Next" पर क्लिक करें। इसका मतलब है कि आपने उस प्रश्न का उत्तर दे दिया है और आप अगले प्रश्न पर जा रहे हैं।</span>
                            </li>
                            <li>
                                <p>(b) If you want to go to any other question, you can click the said question number on the QDB appearing on the right hand side of your screen.</p>
                                <span>(बी) यदि आप किसी अन्य प्रश्न पर जाना चाहते हैं तो आप अपनी स्क्रीन के दायीं ओर दिखाई देने वाली QDB पर दिए गए प्रश्न संख्या पर क्लिक कर सकते हैं।</span>
                            </li>
                            <li>
                                <p>(c) If you have choosen an answer but you would like to visit that question again and review the answer at a later stage, if time permits, then you can click on "Save & Mark for Review".</p>
                                <span>(सी) यदि आपने कोई उत्तर चुना है लेकिन आप उस प्रश्न को फिर से देखना चाहते हैं और बाद में समय मिलने पर उत्तर की समीक्षा करना चाहते हैं तो आप "Save & Mark for Review" कर क्लिक कर सकते हैं।</span>
                            </li>
                            <li>
                                <p>(d)If you have choosen an answer but you would like to change the answer immediately, then you can click on " Clear ". This will clear the option you have selected and then you can select your next option.</p>
                                <span>(डी) यदि आपने कोई उत्तर चुना है लेकिन आप तुरंत जवाब बदलना चाहते हैं तो आप " Clear " पर क्लिक कर सकते हैं। इससे आपके द्वारा चुने गए विकल्प को मिटा दिया जाएगा और फिर आप अपना अलग विकल्प चुन सकते हैं।</span>
                            </li>
                            <li>
                                <p>(e)If you want to quit the exam, then click on "Finish Exam". Once you have clicked this button you cannot review or change any answer. Therefore, you must be very careful in clicking on "Finish Exam.""</p>
                                <span>(ई) यदि आप परीक्षा छोड़ना चाहते हैं तो "Finish Exam" पर क्लिक करें।एक बार जब आप इस बटन पर क्लिक कर लेंगे तो आप किसी भी उत्तर की समीक्षा या परिवर्तन नहीं कर सकते हैं। इसलिए आपको "Finish Exam" पर क्लिक करने में बहुत सावधान रहना चाहिए।</span>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>Once you have finished the exam, your result will be automatically generated in a PDF file and you can take a print out.</p>
                        <span>परीक्षा पूर्ण करने के बाद आपका परिणाम PDF फाईल में स्वचालित रुप से जेनरेट हो जाएगा और आप इसका प्रिंट आउट ले सकते है।</span>
                    </li>
                    <li>
                        <p>The soft copy of the result as well as the 100 questions that you answered, will be automatically sent on your e-mail.</p>
                        <span>परीक्षा परिणाम के साथ-साथ आप के द्वारा उत्तरित 100 प्रश्न सॉफ्ट कॉपी के रुप में स्वतः ही आप के ई.मेल पर चला जाएगा।</span>
                    </li>
                </ol>
                <form onSubmit={onSubmit}>
                    <div style={{ display }} className={classes.accept_instruction}>
                        <input required type="checkbox" onClick={onCheckboxClick} />
                        <label>  I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations</label>
                    </div>

                    <div style={{ display }} className={classes.proceed_to_next}>
                        <div className={classes.next_button}>
                            <PrimaryButton
                                buttonText="I have read the instructions. Take me to the Exam. / निर्देशों को पढ़ा परीक्षा के लिए तैयार"
                                backgroundColor="teal"
                                color="white"
                                padding="7px 20px"
                                fontSize="17px"
                                type="submit"
                            />
                        </div>

                        <p>All The Best/शुभकामनाएँ </p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InstructionContainer;