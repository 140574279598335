import * as actionTypes from "./actionTypes";
import * as APIs from "./apis";
import { loaderStart, loaderEnd, notification } from "../../components/loader";
import { getDataFromStore } from "../getStore";


//APIS
export const onGetQuestions = (id) => {
    return async (dispatch) => {
        loaderStart()
        let res = await APIs.getQuestions(id)
        if (res.status) {
            dispatch(updateQuestions(res.data.userExamQuestions))
            //minutes to second
            dispatch(updateTimer(parseInt(res.data.examTimeRemaining * 60)))
            // dispatch(updateTimer(3800))
            loaderEnd()
        }
        else {
            notification(false, res.message)
        }
    }
};

export const onSaveQuestion = (data, questionNo, next, navigate) => {
    return async (dispatch) => {
        let _data = { ...data }
        dispatch(updateLoader(true))
        let res = await APIs.saveQuestions(_data)
        if (res.status) {
            let questions = getDataFromStore("Exam")
            let _question = [...questions.questions];
            if (data.answers[0].answer >= 0) {
                _question[questionNo].userResponse = data.answers[0].answer
            }
            if (data.answers[0].has_skipped >= 0) {
                _question[questionNo].hasSkipped = data.answers[0].has_skipped
            }
            if (data.answers[0].has_marked_for_review >= 0) {
                _question[questionNo].hasMarkedForReview = data.answers[0].has_marked_for_review
            }
            if (_question.length != questionNo + 1) {
                questionNo = questionNo + 2
            }
            else if (_question.length == questionNo + 1) {
                questionNo = questionNo + 1
            }
            dispatch(updateQuestions(_question))
            console.log(next, questionNo);
            if (next)
                navigate("/question/" + questionNo)
        }
        else {
            notification(false, res.message)
        }
        dispatch(updateLoader(false))
    }
};

export const onSubmit = (navigate) => {
    return async (dispatch) => {

        let auth = getDataFromStore("Auth")

        let questions = getDataFromStore("Exam")
        let _question = [];
        questions.questions.map((q) => {
            _question.push({ "exam_id": auth.allExam?.userCurrentSlotData[0].examId, "quest_id": q.mcqId, "answer": q.userResponse ? q.userResponse : 0, "has_skipped": q.hasSkipped, "has_marked_for_review": q.hasMarkedForReview })
        })
        loaderStart()
        let res = await APIs.saveQuestions({ answers: _question })
        let res2 = await APIs.submitExam({
            "exam_id": auth.allExam?.userCurrentSlotData[0].examId
        })
        if (res2.status) {
            window.location.href = "/feedback"
            loaderEnd()
        }
        else {
            notification(false, res.message)
        }
    }
};


export const updateQuestions = (data) => {
    return {
        type: actionTypes.UPDATE_QUESTIONS,
        payload: data,
    };
};


export const updateTimer = (data) => {
    return {
        type: actionTypes.UPDATE_TIMER,
        payload: data,
    };
};


export const updateLoader = (data) => {
    return {
        type: actionTypes.UPDATE_LOADER,
        payload: data,
    };
};
