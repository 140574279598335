import * as actionTypes from "./actionTypes";
import * as APIs from "./apis";
import { loaderStart, loaderEnd, notification } from "../../components/loader";


//APIS

export const onUserCheck = (username) => {
    return async (dispatch) => {
        loaderStart()
        let res = await APIs.userCheck(username)
        if (res.status) {
            dispatch(updateExam(res.data))
            localStorage.setItem("EXAMINATION_SOFTWARE_SUBJECT", JSON.stringify(res.data));
            if (!res.data?.userCurrentSlotData?.length && !res.data?.userTodaySlotData?.length) {
                notification(false, "The Aadhar number is not mapped for Today Exam")
            } else if (res.data?.userCurrentSlotData?.length == 0) {
                notification(false, "You do not a have any exam now.")
            }
            else if (res.data?.userCurrentSlotData?.length) {
                if (res.data?.userCurrentSlotData[0].completed) {
                    notification(false, "You have finished the exam")
                }
                else if (res.data?.userCurrentSlotData[0].entered) {
                    notification(false, "You have already entered the exam")
                }

            }
            loaderEnd()
        }
        else {
            notification(false, res.message)
        }
    }
};

export const onLogin = (username, password, navigate) => {
    return async (dispatch) => {
        loaderStart()
        let res = await APIs.handleLogin(username, password)
        if (res.status) {
            dispatch(updateAuthData(res.data))
            localStorage.setItem("EXAMINATION_SOFTWARE_AUTH", JSON.stringify(res.data))
            // if(process.env.REACT_APP_SKIP_LANGUAGE){
            //     navigate("/instruction")
            // }
            // else{
                
            // }
            navigate("/dashboard")
            loaderEnd()
        }
        else {
            notification(false, res.message)
        }
    }
};

export const updateAuthData = (data) => {
    return {
        type: actionTypes.UPDATE_AUTH_DATA,
        payload: data,
    };
};

export const updateExam = (data) => {
    return {
        type: actionTypes.UPDATE_EXAM,
        payload: data,
    };
};





export const getDataFromLocal = () => {
    return async (dispatch) => {
        let data = localStorage.getItem("EXAMINATION_SOFTWARE_AUTH") ? JSON.parse(localStorage.getItem("EXAMINATION_SOFTWARE_AUTH")) : null;
        if (data) {
            dispatch(updateAuthData(data))
        }

        let data2 = localStorage.getItem("EXAMINATION_SOFTWARE_SUBJECT") ? JSON.parse(localStorage.getItem("EXAMINATION_SOFTWARE_SUBJECT")) : {};
        if (data2) {
            dispatch(updateExam(data2))
        }
    }
};

export const onLogout = (navigate) => {
    return async (dispatch) => {
        localStorage.removeItem("EXAMINATION_SOFTWARE_AUTH")
        localStorage.removeItem("EXAMINATION_SOFTWARE_SUBJECT")
        dispatch(updateAuthData(null))
        dispatch(updateExam({}))
        navigate("/")
    }
}
