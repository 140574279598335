
import { apiFunction } from "../../apiCall/function";
import * as API from "../../apiCall/urls/auth";

export const userCheck = (username) => {
    return apiFunction(API.USER_CHECK + username, "get", null, null, null);
};

export const handleLogin = (username, password) => {
    return apiFunction(API.LOGIN + username + "/" + password, "get", null, null, null);
};

export const checkFirstLogin = (username, password) => {
    return apiFunction(API.FIRST_LOGIN   + username + "&password=" + password, "get", null, null, null);
};