import * as actionTypes from "./actionTypes";

const initialState = {
    questions: null,
    timer: null,
    loader: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_QUESTIONS:
            return {
                ...state,
                questions: action.payload,
            };
        case actionTypes.UPDATE_TIMER:
            return {
                ...state,
                timer: action.payload,
            };
        case actionTypes.UPDATE_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
