import React from "react";
import classes from "./styles.module.scss";
import QuestionContainer from "../../container/questionContainer";

const QuestionPage = () => {
    return(
        <>
            <QuestionContainer />
        </>
    )
}

export default QuestionPage;