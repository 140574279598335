import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

import classes from "./styles.module.scss";
import SiteLogo from "../../../assets/images/logo.png";
import Online from "../../../assets/images/online.png";
import Offline from "../../../assets/images/offline.png";

import ProfileIcon from "../../../assets/images/profile.png";
import { updateTimer, onSubmit } from "../../../store/questions/actions";
import { useInterval, useNetworkStatus } from '../../../utils/customHooks'
import { updateTime } from '../../../store/questions/apis'
import { dateIndian, timeFormat } from '../../../utils/dateFunction'

const Header = (props) => {

    // console.log(props.exam);
    const navigate = useNavigate()
    const [time, setTime] = useState(null);
    const { isOnline } = useNetworkStatus();

    const updateTimer = async () => {
        if (time && time >= 0 && window.location.pathname.includes("question")) {
            console.log(time);
            await updateTime({
                "exam_id": props.auth.allExam?.userCurrentSlotData[0].examId,
                "time": (time / 60)
            })
        }
    }

    useInterval(updateTimer, 1000 * 60)

    useEffect(() => {
        if (props.exam.timer) {
            setTime(props.exam.timer)
        }
    }, [props.exam.timer])

    useEffect(() => {
        // exit early when we reach 0
        if (time === 0) {
            // console.log("test");
            updateTimer()
            props.onSubmit(navigate)
            setTime(null)
        }

        if (!time) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTime(time - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [time]);

    useEffect(() => {
        if (props.exam.timer) {
            setInterval(() => {

                let newTimer = props.exam.timer - 1
                // toHoursAndMinutes(newTimer)
                // props.updateTimer(newTimer)
            }, 1000)
        }
    }, [props.exam.timer])

    const toHoursAndMinutes = (totalSeconds) => {
        const totalMinutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }

    // console.log(props.exam.timer);


    return (
        <div className={classes.header_container}>
            <div className={classes.header_content}>
                <div className={classes.logo}>
                    <img src={SiteLogo} alt="logo" />
                </div>
                {
                    props.auth.allExam?.userCurrentSlotData?.length >= 1 &&
                    <div className={classes.exam_info}>
                        <div className={classes.date_time}>
                            <p>BOR EXAM</p>
                            |
                            <p>{dateIndian(props.auth.allExam?.userCurrentSlotData[0].date)}</p>
                            |
                            <p>{timeFormat(props.auth.allExam?.userCurrentSlotData[0].startTime)} - {timeFormat(props.auth.allExam?.userCurrentSlotData[0].endTime)}</p>
                        </div>
                        <div className={classes.remaining_time}>
                            <p>Remaining Time: <span>{toHoursAndMinutes(time)}</span></p>
                        </div>
                    </div>
                }


                <>
                    <div className={classes.profile_section}>
                        <div style={{ display: "flex" }}>
                            {isOnline ? <img className={classes.online} src={Online} /> : <img className={classes.online} src={Offline} />}
                        </div>
                        {
                            props.auth.auth?.name &&
                            <div className={classes.profile_info}>
                                <p>{props.auth.auth?.name}</p>
                                {
                                    window.location.pathname == "/result-details" && <div class={classes.dropdown_content}>
                                        <a href="/">Logout</a>
                                    </div>
                                }
                                <div className={classes.profile_pic}>
                                    <img src={ProfileIcon} alr="profile pic" />
                                </div>
                            </div>
                        }
                        {/* <div className={classes.profile_info}>
                            <p>Amrit</p>
                            {
                                window.location.pathname == "/result-details" && <div class={classes.dropdown_content}>
                                    <a href="/">Logout</a>
                                </div>
                            }

                            <div className={classes.profile_pic}>
                                <img src={ProfileIcon} alr="profile pic" />
                            </div>
                        </div> */}
                    </div>
                </>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
        exam: state.Exam
    }
}

const mapDispatchToProps = { updateTimer, onSubmit }
export default connect(mapStateToProps, mapDispatchToProps)(Header);