import React from "react";
import classes from "./styles.module.scss";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InstructionContainer from "../../instructionContainer";

const style = {
    position: 'absolute',
    top: '3vh',
    borderRadius: 4,
    p: 0,
    outline: 'none',
    width: '100%',
    height: 'calc(100vh - 90px)',
};

const InstructionModal = (props) => {
    return(
        <div className={classes.add_checker_modal}>
            <Modal
                open={props.open}
                onClose={props.close}
            >
                <Box sx={style}>
                    <div className={classes.instruction_modal_container}>
                        <div className={classes.instruction_modal_content}>
                            <div className={classes.instruction_modal_head}>
                                <p>INSTRUCTION</p>
                                <span onClick={props.close}>Close</span>
                            </div>
                            <div className={classes.instruction_modal_body}>
                                <InstructionContainer display="none"/>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default InstructionModal;