import { combineReducers } from "redux";

import Auth from "./auth/reducer";
import Exam from "./questions/reducer";


const rootReducer = combineReducers({
  Auth,
  Exam
});

export default rootReducer;
