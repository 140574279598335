import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from "../../../components/table";
import PrimaryButton from "../../../components/buttons";
import SubmitNotifyModal from "./submitNotifyModal";

const style = {
    position: 'absolute',
    top: '3vh',
    borderRadius: 4,
    p: 0,
    outline: 'none',
    width: '100%',
    height: 'calc(100vh - 90px)',
};

const FinalSubmitModal = (props) => {

    const [c, setC] = useState(0)

    useEffect(() => {
        getNumber()
    }, [])

    const columns = [
        {
            name: "question",
            label: "Question"
        },
        {
            name: "answer",
            label: "Answer"
        }
    ]

    const getRows = (pos) => {
        let _ques = [...props.questions];
        let _column = []
        for (let i = pos; i < pos + 10; i++) {
            _column.push({
                question: i + 1,
                answer: _ques[i] ? _ques[i].userResponse : ""
            })
        }
        return _column;

    }

    const getNumber = () => {
        let _ques = [...props.questions];
        let number = 0
        for (let i = 0; i < _ques.length; i++) {
            if (_ques[i].userResponse) {
                number++
            }
        }
        setC(number)
    }

    return (
        <div className={classes.add_checker_modal}>
            <Modal
                open={props.open}
                onClose={props.close}
            >
                <Box sx={style}>
                    <div className={classes.final_submit_modal_container}>
                        <div className={classes.final_submit_modal_content}>
                            <div className={classes.final_submit_modal_head}>
                                <p>Confirm Final Submission / अंतिम रूप से जमा करे</p>
                                <span onClick={props.close}>Close</span>
                            </div>
                            <div className={classes.final_submit_modal_body}>

                                <p className={classes.attend_info}>You have attempted {c} out of 100</p>

                                <div className={classes.qns_ans_table}>
                                    <div className={classes.table_one}>
                                        <Table rows={getRows(0)} columns={columns} />
                                        <Table rows={getRows(10)} columns={columns} />
                                        <Table rows={getRows(20)} columns={columns} />
                                        <Table rows={getRows(30)} columns={columns} />
                                        <Table rows={getRows(40)} columns={columns} />
                                    </div>
                                    <div className={classes.table_one}>
                                        <Table rows={getRows(50)} columns={columns} />
                                        <Table rows={getRows(60)} columns={columns} />
                                        <Table rows={getRows(70)} columns={columns} />
                                        <Table rows={getRows(80)} columns={columns} />
                                        <Table rows={getRows(90)} columns={columns} />
                                    </div>
                                </div>

                                <div className={classes.final_declaration}>
                                    <p className={classes.one}>Do you want to Finish and submit the exam </p>
                                    <p className={classes.two}>Once submitted you will not be able to change answers.</p>
                                    <p className={classes.three}>जमा एवं समाप्त करने हेतु आश्वस्त हैं ?</p>
                                    <p className={classes.four}>एक बार Final submit / जमा करने के बाद आप पुनः उत्तर नहीं बदल पाएंगे| </p>
                                </div>

                                <div className={classes.bottom_buttons}>
                                    <PrimaryButton
                                        buttonText="Final Submit / जमा करें"
                                        backgroundColor="red"
                                        color="white"
                                        fontSize="15px"
                                        fontWight="500"
                                        onClick={props.finalSubmit}
                                    />
                                    <PrimaryButton
                                        buttonText=" Cancel / रद्द करें"
                                        backgroundColor="blue"
                                        color="white"
                                        fontSize="15px"
                                        margin="0px 0px 0px 15px"
                                        fontWight="500"
                                        onClick={props.close}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <SubmitNotifyModal open={showSubmitNotify} close={() => setShowSubmitNotify(false)} /> */}
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default FinalSubmitModal;