import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Table = (props) => {
    // console.log(props)
    const [allLabels, setAllLabels] = useState([])
    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            let allLabels = Object.keys(props.rows[0]);
            setAllLabels(allLabels)
        }
    }, [props.rows])

    const [count, setCount] = useState('');
    const handleChange = (event) => {
        setCount(event.target.value);
    };

    const handlePage = (e, v) => {
        console.log(v);
    }

    const handleSort = (name, order, sort) => {        
        if (sort) {
            if (order === "desc") {
                props.handleSort({
                    "column": name,
                    "order": "asc"
                })
            }
            else {
                props.handleSort({
                    "column": name,
                    "order": "desc"
                })
            }
        }
    }

    return (
        <>
            <table className={classes.tableDesign}>
                <thead>
                    <tr>
                        {
                            props.columns.map((c, i) => (
                                <th key={i} >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => handleSort(c.name, props.sort?.order, c.sort)}
                                    >
                                        {c.label}
                                        {
                                            (props.sort?.column === c.name && (c.sort))
                                                ?
                                                <>
                                                    {
                                                        props.sort?.order === "asc"
                                                            ?
                                                            <ArrowUpwardIcon
                                                                style={{ fontSize: 16 }}
                                                            />
                                                            :
                                                            <ArrowDownwardIcon
                                                                style={{ fontSize: 16 }}
                                                            />
                                                    }
                                                </>
                                                : (c.sort)
                                                    ?
                                                    <ArrowDownwardIcon
                                                        style={{ fontSize: 16, color: '#b3b3b3' }}
                                                    />
                                                    : null
                                        }
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.rows && props.rows.length > 0 && props.rows.map((r, i) => (
                            <tr>
                                {
                                    allLabels && allLabels.length > 0 && allLabels.map((a, j) => (
                                        <td>
                                            {r[a]}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default Table;