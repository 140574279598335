import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./styles.module.scss";
import PrimaryButton from "../../../components/buttons";

const RightStatus = (props) => {

    const [state, setState] = useState({
        notVisited: 0,
        answered: 0,
        markedAsReview: 0,
        visited: 0
    })

    const [allQuestion, setAllQuestion] = useState("")

    const navigate = useNavigate()

    const getClass = (data, i) => {
        let _class = ""
        
        if (i.hasMarkedForReview) {
            data.markedAsReview = data.markedAsReview + 1;
            _class = "review"
        }
        else if (i.userResponse) {
            data.answered = data.answered + 1;
            _class = "answered"
        }
        else if (i.hasSkipped) {
            data.visited = data.visited + 1;
            _class = "visited"
        }
        else {
            data.notVisited = data.notVisited + 1;
            _class = ""
        }
        return {
            data,
            className: _class
        };
    }

    useEffect(() => {
        getNumber()
    }, [props.questions, props.number])

    const getNumber = () => {
        let _len = []
        let data = {
            notVisited: 0,
            answered: 0,
            markedAsReview: 0,
            visited: 0
        }

        for (let i = 0; i < props.length; i++) {
            let _data = getClass(data, props.questions[i])
            data = _data.data
            _len.push(<p
                onClick={() => navigate("/question/" + (i + 1))}
                className={`${classes.index} ${classes[_data.className]} ${props.number == i + 1 ? classes.active : ""}`}

            >{i + 1}</p>)
        }
        setState(data)
        setAllQuestion(_len)
        // return _len;
    }

    return (
        <div className={classes.status_content}>
            <div className={classes.status_head}>
                <div className={classes.status_one}>
                    <p className={classes.not_visited}> <span>{state.notVisited}</span> Not Visited</p>
                    <p className={classes.answered}><span>{state.answered}</span> Answered</p>
                </div>
                <div className={classes.status_two}>
                    <p className={classes.review}><span>{state.markedAsReview}</span> Marked As Review</p>
                    <p className={classes.visited}><span>{state.visited}</span> Visited</p>
                </div>
            </div>

            <div className={classes.question_index}>
                <div className={classes.index_section}>
                    {allQuestion}
                </div>
            </div>
        </div>
    )
}

export default RightStatus;