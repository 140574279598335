import React from "react";
import classes from "./styles.module.scss"

const PrimaryButton = ({ buttonText, width, height, backgroundColor, color, padding, marginTop, margin, fontSize, border, icon, icon_two, fontWight, disabled, float, ...props }) => {
    return (
        <button className={classes.button}
            {...props}
            style={{ width, height, backgroundColor, color, marginTop, margin, fontSize, border, padding, fontWight, float }}
            disabled={disabled}
        >
            {icon}
            {icon_two}
            {buttonText}
        </button>
    )
}

export default PrimaryButton;